import React, { useCallback, useContext } from 'react'
import LogoIcon from 'components/App/Header/Logo/LogoIcon'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Clickable from 'components/Common/Clickable/Clickable'
import LayoutContainer from 'components/Common/LayoutContainer'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import ModalContext from 'contexts/ModalContext'
import AgentHubExpressionOfInterestModal from './AgentHubExpressionOfInterestModal'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { authLogout } from 'actions/AuthActions'
import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'

const HeaderContainer = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};

  ${mediaQueryUp.tablet} {
    border: none;
  }
`

const HeaderContentGroup = styled(Group)`
  height: ${rem(64)};

  ${mediaQueryUp.tablet} {
    border: none;
    height: ${rem(74)};
  }
`

function AgentHubGuestHeader() {
  const showModal = useContext(ModalContext)

  const dispatch = useAppDispatch()
  const toggleModal = useCallback(() => {
    showModal(<AgentHubExpressionOfInterestModal />)
  }, [showModal])

  const onLogin = useCallback(() => {
    showModal(<AccountAccessModal />)
  }, [showModal])

  const loggedIn = useAppSelector(selectLoggedIn)
  const onLogout = useCallback(() => dispatch(authLogout()), [dispatch])

  return (
    <Group direction="vertical">

      <HeaderContainer>
        <LayoutContainer>
          <HeaderContentGroup
            direction="horizontal"
            horizontalAlign="space-between"
            gap={8}
            verticalAlign="center"
          >
            <Clickable to="/">
              <LogoIcon/>
            </Clickable>

            <Group direction="horizontal" gap={4}>
              {!loggedIn && <TextButton kind="tertiary" onClick={onLogin}>
                Log in
              </TextButton>}
              <CSSBreakpoint min="tablet">
                {!loggedIn && <TextButton kind="primary" onClick={toggleModal}>
                  Register your interest
                </TextButton>}
              </CSSBreakpoint>
              {loggedIn && <TextButton kind="primary" onClick={onLogout}>
                Log out
              </TextButton>}
            </Group>
          </HeaderContentGroup>
        </LayoutContainer>
      </HeaderContainer>
    </Group>
  )
}

export default AgentHubGuestHeader
